<template>
  <el-dialog
    title="配置资源"
    :visible.sync="show"
    width="1200px"
    :closeOnClickModal="false"
    @open="initData"
    :before-close="closeFn"
  >
    <add_edit_source
      :showDialog="showAddDialog"
      :isEdit="isEdit"
      :sourceData="this.clickCurrentRow.row"
      @sure="addSureFn"
      @closeDialog="(showAddDialog = false), (isEdit = false)"
    />
    <sourceMatch
      :showMatch="showMatchDialog"
      :tenantPermissionId="tenantPermissionId"
      @closeFn="showMatchDialog = false"
    />
    <el-table
      stripe
      :data="tableList"
      ref="table"
      style="width: 100%;"
      row-key="code"
      :max-height="maxHeight + 'px'"
      border
      :default-expand-all="true"
      :header-cell-style="{ 'text-align': 'left ' }"
      :cell-style="{ 'text-align': 'left ' }"
      :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
    >
      <el-table-column label="资源编号">
        <template slot-scope="{ row }">
          <span
            v-if="row.parentId == undefined"
            class="db"
            :style="{
              'margin-top': row.children.length ? '-20px' : '0',
              'margin-left': '20px'
            }"
            >{{ row.code }}</span
          >
          <span v-else style="margin-left:50px;margin-top:-20px" class="db">
            {{ row.code }}
          </span>
        </template>
      </el-table-column>
      <el-table-column label="资源名称">
        <template slot-scope="{ row }">
          <span>{{ row.name }}</span>
        </template>
      </el-table-column>
      <el-table-column label="资源描述">
        <template slot-scope="{ row }">
          <span>{{ row.description }}</span>
        </template>
      </el-table-column>
      <el-table-column
        label="操作"
        width="267"
        :fixed="tableList.length ? 'right' : ''"
      >
        <template slot-scope="{ row, $index }" v-if="row.parentNode != 'none'">
          <el-link
            class="link-box"
            type="primary"
            @click="addRow(row, true)"
            v-if="row.children"
            >增加一级</el-link
          >
          <el-link
            class="link-box"
            type="primary"
            @click="addRow(row, false)"
            v-if="row.children"
            >增加二级</el-link
          >

          <el-link
            type="primary"
            class="link-box"
            @click="matchFn(row)"
            :disabled="row.optionBtn"
            >匹配</el-link
          >

          <el-link
            type="primary"
            class="link-box"
            @click="editRow(row)"
            :disabled="row.optionBtn"
            >编辑</el-link
          >
          <el-popconfirm title="确定删除吗？" @confirm="sureDelteFn(row)">
            <el-link class="link-box" type="primary" slot="reference"
              >删除</el-link
            >
          </el-popconfirm>
        </template>
      </el-table-column>
      <div slot="empty">
        <span class="db" style="margin-top:10px">暂无数据</span>
        <el-button type="primary" class=" addBtn bb" @click="addRow(null, true)"
          >新增</el-button
        >
      </div>
    </el-table>
  </el-dialog>
</template>

<script>
import {
  add_space_source,
  get_space_source,
  delete_space_source
} from '@/api/space';
import { tenant_ifrepeat } from '@/api/apply';
export default {
  name: 'space_source',
  props: {
    show: Boolean,
    spaceId: ''
  },
  components: {
    add_edit_source: () => import('./add_space_source.vue'),
    sourceMatch: () => import('./space_source_match.vue')
  },
  data() {
    return {
      tableList: [],
      showAddDialog: false,
      clickCurrentRow: {},
      isLv1: false,
      // souceNames: '',
      tenantId: null, //租户权限id
      tenantPermissionId: null, //应用资源ID
      showMatchDialog: false,
      isEdit: false,
      maxHeight: 500
    };
  },
  mounted() {},
  methods: {
    initData() {
      this.get_space_source();
    },
    async get_space_source(cb) {
      const res = await get_space_source({ spaceId: this.spaceId });
      this.tableList = Object.values(this.setTableData(res)) || [];
      cb && cb();
    },
    setTableData(arr) {
      let newList = {};
      let noParentNodes = {
        name: '未知资源',
        children: [],
        code: '未知code',
        parentNode: 'none'
      };
      arr.forEach(ele => {
        let obj = {};
        obj = ele;
        obj.tenantId = ele.tenantId.toString();
        obj.tenantPermissionId = ele.tenantPermissionId.toString();
        obj.rootId = ele.rootId.toString();
        if (ele.parentId == undefined) {
          //一级
          obj.children = [];
          newList[obj.rootId] = obj;
        } else {
          if (newList[ele.parentId]) {
            obj.parentId = ele.parentId.toString();
            newList[ele.parentId].children.push(obj);
          } else {
            obj.optionBtn = true;
            noParentNodes.children.push(obj);
          }
        }
      });
      return newList;
    },
    addRow(row, isFirst) {
      this.isEdit = false;
      this.isLv1 = isFirst;
      this.showAddDialog = true;
      this.clickCurrentRow.row = { ...row };
      // this.clickCurrentRow.idx = row
      //   ? this.filterRowIdx(this.tableList, row.tenantPermissionId)
      //   : null;
      // console.log(this.clickCurrentRow.idx, '77777')
    },
    editRow(row) {
      this.clickCurrentRow.row = row;
      this.showAddDialog = true;
      this.isEdit = true;
      console.log(row);
    },
    addSureFn(v) {
      this.saveFn(v);
    },
    //保存
    saveFn(v) {
      v.spaceId = this.spaceId;
      let params = {};
      if (this.isLv1 == 1) {
        params = { ...v };
      } else {
        params = { ...v, ...{ parentId: this.clickCurrentRow.row.rootId } };
      }
      tenant_ifrepeat({
        id: !this.isEdit
          ? ''
          : this.clickCurrentRow.row.tenantPermissionId.toString(),
        name: 'code',
        value: v.code
      }).then(res1 => {
        if (res1) {
          this.$message.warning('资源编码已存在，请重新输入');
          return;
        } else {
          tenant_ifrepeat({
            id: !this.isEdit
              ? ''
              : this.clickCurrentRow.row.tenantPermissionId.toString(),
            name: 'name',
            value: v.name
          }).then(res2 => {
            if (res2) {
              this.$message.warning('资源名称已存在，请重新输入');
              return;
            } else {
              let a = { ...this.clickCurrentRow.row };
              if (this.isEdit) {
                if (a.children) {
                  delete a.children;
                }
                if (params.parentId) {
                  delete params.parentId;
                }
              }
              add_space_source(
                this.isEdit ? { ...a, ...params } : { ...params }
              ).then(res => {
                this.get_space_source(() => {
                  this.showAddDialog = false;
                  this.$message.success(this.isEdit ? '保存成功' : '新增成功');
                });
              });
            }
          });
        }
      });
    },
    sureDelteFn(row) {
      this.clickCurrentRow.row = row;
      // this.clickCurrentRow.idx = this.filterRowIdx(
      //   !row.parentId ? this.tableList : this.findParent(row.parentId).children,
      //   row.tenantPermissionId
      // );
      this.delete_space_source(row);
    },
    async delete_space_source(row) {
      const res = await delete_space_source(row);
      this.get_space_source(() => {
        this.$message.success('删除成功');
      });
    },
    //找idx
    // filterRowIdx(arr, tenantPermissionId) {
    //   let idx = undefined;
    //   arr.forEach((cell, key) => {
    //     if (cell.tenantPermissionId == tenantPermissionId) {
    //       idx = key;
    //     }
    //   });
    //   return idx;
    // },
    // //找父级
    // findParent(parentId) {
    //   let a = this.tableList.filter(cell => {
    //     return cell.rootId == parentId;
    //   });
    //   return a.length ? a[0] : {};
    // },
    matchFn(row) {
      this.clickCurrentRow.row = row;
      this.tenantPermissionId = row.tenantPermissionId;
      this.showMatchDialog = true;
    },
    closeFn() {
      this.$emit('close');
    }
  }
};
</script>

<style lang="scss" scoped>
.addBtn {
  padding: 10px 40px;
  margin-bottom: 30px;
}
.link-box {
  margin-right: 10px;
}
</style>
