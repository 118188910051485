var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('el-dialog',{attrs:{"title":"配置资源","visible":_vm.show,"width":"1200px","closeOnClickModal":false,"before-close":_vm.closeFn},on:{"update:visible":function($event){_vm.show=$event},"open":_vm.initData}},[_c('add_edit_source',{attrs:{"showDialog":_vm.showAddDialog,"isEdit":_vm.isEdit,"sourceData":this.clickCurrentRow.row},on:{"sure":_vm.addSureFn,"closeDialog":function($event){(_vm.showAddDialog = false), (_vm.isEdit = false)}}}),_c('sourceMatch',{attrs:{"showMatch":_vm.showMatchDialog,"tenantPermissionId":_vm.tenantPermissionId},on:{"closeFn":function($event){_vm.showMatchDialog = false}}}),_c('el-table',{ref:"table",staticStyle:{"width":"100%"},attrs:{"stripe":"","data":_vm.tableList,"row-key":"code","max-height":_vm.maxHeight + 'px',"border":"","default-expand-all":true,"header-cell-style":{ 'text-align': 'left ' },"cell-style":{ 'text-align': 'left ' },"tree-props":{ children: 'children', hasChildren: 'hasChildren' }}},[_c('el-table-column',{attrs:{"label":"资源编号"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(row.parentId == undefined)?_c('span',{staticClass:"db",style:({
            'margin-top': row.children.length ? '-20px' : '0',
            'margin-left': '20px'
          })},[_vm._v(_vm._s(row.code))]):_c('span',{staticClass:"db",staticStyle:{"margin-left":"50px","margin-top":"-20px"}},[_vm._v(" "+_vm._s(row.code)+" ")])]}}])}),_c('el-table-column',{attrs:{"label":"资源名称"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var row = ref.row;
return [_c('span',[_vm._v(_vm._s(row.name))])]}}])}),_c('el-table-column',{attrs:{"label":"资源描述"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var row = ref.row;
return [_c('span',[_vm._v(_vm._s(row.description))])]}}])}),_c('el-table-column',{attrs:{"label":"操作","width":"267","fixed":_vm.tableList.length ? 'right' : ''},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var row = ref.row;
          var $index = ref.$index;
return (row.parentNode != 'none')?[(row.children)?_c('el-link',{staticClass:"link-box",attrs:{"type":"primary"},on:{"click":function($event){return _vm.addRow(row, true)}}},[_vm._v("增加一级")]):_vm._e(),(row.children)?_c('el-link',{staticClass:"link-box",attrs:{"type":"primary"},on:{"click":function($event){return _vm.addRow(row, false)}}},[_vm._v("增加二级")]):_vm._e(),_c('el-link',{staticClass:"link-box",attrs:{"type":"primary","disabled":row.optionBtn},on:{"click":function($event){return _vm.matchFn(row)}}},[_vm._v("匹配")]),_c('el-link',{staticClass:"link-box",attrs:{"type":"primary","disabled":row.optionBtn},on:{"click":function($event){return _vm.editRow(row)}}},[_vm._v("编辑")]),_c('el-popconfirm',{attrs:{"title":"确定删除吗？"},on:{"confirm":function($event){return _vm.sureDelteFn(row)}}},[_c('el-link',{staticClass:"link-box",attrs:{"slot":"reference","type":"primary"},slot:"reference"},[_vm._v("删除")])],1)]:undefined}}],null,true)}),_c('div',{attrs:{"slot":"empty"},slot:"empty"},[_c('span',{staticClass:"db",staticStyle:{"margin-top":"10px"}},[_vm._v("暂无数据")]),_c('el-button',{staticClass:" addBtn bb",attrs:{"type":"primary"},on:{"click":function($event){return _vm.addRow(null, true)}}},[_vm._v("新增")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }